import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/usr/src/app/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "region-specific-requests"
    }}>{`Region-specific requests`}</h1>
    <p>{`Planday's API are hosted in two distinct regions in the world: Europe and Australia. To reduce end user latencies, Planday operate a CDN and by default route your HTTP request to the fastest region available to you. `}</p>
    <p>{`To learn which region was automatically chosen for your request, check the `}<inlineCode parentName="p">{`X-Backend-Region`}</inlineCode>{` header of the response to see whether it was routed to `}<inlineCode parentName="p">{`westeu`}</inlineCode>{` or `}<inlineCode parentName="p">{`aueast`}</inlineCode>{`.  This applies to all requests except for token exchange which does not contain this header. `}</p>
    <h3 {...{
      "id": "when-to-specify-a-region"
    }}>{`When to specify a region`}</h3>
    <p>{`Not specifying a region can lead to longer response times if your service is sending requests, for instance, from Europe but tries to reach a portal in Australia. In such cases, you would benefit from specifying the target region where your request should be routed.
To learn where a portal is located, check its country using the `}<a parentName="p" {...{
        "href": "https://openapi.planday.com/api/portal#tag/Portal/operation/PortalGet_GetPortalInfo"
      }}>{`GET portal information`}</a>{` endpoint. `}</p>
    <h3 {...{
      "id": "routing-requests-to-a-region"
    }}>{`Routing requests to a region`}</h3>
    <p>{`You can specify the region where your request should be routed by adding a `}<inlineCode parentName="p">{`X-OpenAPI-Region`}</inlineCode>{` header to your HTTP request and specifying either `}<inlineCode parentName="p">{`westeu`}</inlineCode>{` or `}<inlineCode parentName="p">{`aueast`}</inlineCode>{` as its values. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      